p, h1, h2, h3, h4, h5, h6, span, label {
    font-size: 1rem;
}

.btn, input, div {
    font-size: 1rem !important;
}

.u-primary-bg {
    background-color: #00B624 !important;
}

.u-primary-co {
    background-color: #00B624 !important;
}

.u-primary-btn {
    background-color: #00B624 !important;
    border: 1px solid  #00B624 !important;
}

.custom-control-input:checked~.custom-control-label::before {
    background-color: #00B624;
}

.custom-control-input:not(:disabled):active~.custom-control-label::before {
    background-color: #D7FFDF;
    border-color: #D7FFDF;
}

/* .nav-link.active > i{
    color: #00B624 !important;
} */

.main-content-container {
    padding-bottom: 50px !important;
}

.under_line {
    height: 4px;
    border-radius: 25px;
    margin-top: 10px;
    background-color: transparent;
}

b {
    font-weight: 700 !important;
}

body {
    background: #ffffff url('../images/layout/offline.png') no-repeat -1000px -1000px;
}