.btn {
   max-width: 76px !important;
   height: 100%;
   display: flex !important;
   flex-direction: column;
   align-items: center;
   padding-left: 8px !important;
   padding-right: 8px !important;
}

.btn_group {
    align-items: flex-start;
    display: flex !important;
    justify-content: flex-end !important;
    height: 115px;
    background-color: #fbfbfb;
 }

.title {
    font-size: 20px !important;
    margin-bottom: 20px !important;
    font-weight: 700 !important;
}

.map {
    width: 100%;
    min-height: 200px;
    background-color: #FFF;
    border-radius: 5px;
}

.btn_container_ico {
    width: 50px;
    height: 50px;
    background-color: #00B624;
    justify-content: center;
    display: flex;
    align-items: center;
    border-radius: 100%;
}

.btn_label {
    width: 100%;
    text-align: center;
    font-size: 13px !important;
}

.btn_container {
    width: 100% !important;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-bottom: 8px;
}

.ico {
    color: #FFF;
    font-weight: 600;
    font-size: 1.3rem !important;
}